import { useRouter } from 'next/router'
import React from 'react'

import NoticeBlock from '../NoticeBlock'

const PageNotice = () => {
  const router = useRouter()
  if (!(router.query.notice || router.query.alert || router.query.error)) {
    return null
  }
  return (
    <div className="top-16 lg:top-20 absolute w-full z-10">
      {router.query.notice && (
        <NoticeBlock
          className="bg-white"
          size="lg"
          title={router.query.notice as string}
          variant="success"
        />
      )}
      {(router.query.alert || router.query.error) && (
        <NoticeBlock
          className="bg-white"
          size="lg"
          title={(router.query.alert || router.query.error) as string}
          variant="danger"
        />
      )}
    </div>
  )
}

export default PageNotice
